import store from '../../store'
import { Message } from 'element-ui'

export default (to, from, next) => {
  if (to.query.hasOwnProperty('TransactionID')) {
    const promise = new Promise((resolve) => resolve(true))
    promise
      .then(() => {
        return store.dispatch('finalBook', { TransactionID: to.query.TransactionID })
      })
      .then(response => {
        next({ name: 'newbook', params: { id: response } })
      })
      .catch(err => {
        let error = JSON.parse(err.message)
        if (error.hasOwnProperty('id')) {
          Message({
            showClose: true,
            message: error.message,
            type: 'error'
          })
          next({ name: 'newbook', params: { id: error.id } })
        } else {
          next('error')
        }
      })
  } else {
    next()
  }
}
