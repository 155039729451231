import HTTP from './http-common'

let BookingResource = {
  get () {
    return HTTP.get('booking')
  },

  update (id, body) {
    return HTTP.put('booking/' + id, body)
  },

  create (body) {
    return HTTP.post('booking/', body)
  },

  final (body) {
    return HTTP.post('booking/final/', body)
  },

  refund (body) {
    return HTTP.post('booking/refund/', body)
  },

  remote (id) {
    return HTTP.delete('booking/' + id)
  },

  repeat (id) {
    return HTTP.put('booking/repeat/' + id)
  }
}

export default BookingResource
