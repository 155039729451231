import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'

import AuthService from './api/auth.service'

Vue.config.productionTip = false

function initialisation () {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

(async function () {
  if (localStorage.getItem('token')) {
    try {
      const user = await AuthService.getCurrentUser()
      store.dispatch('autoLogin', user)
      store.dispatch('getPersonalPromo', user._id)
      initialisation()
    } catch (e) {
      initialisation()
    }
  } else {
    initialisation()
  }
})()

let fixElSelect = function () {
  document.querySelectorAll('.el-select[data-is-search="true"]:hover').forEach((v) => {
    let elInput = document.querySelector('.el-select[data-is-search="true"]:hover input[readonly]')
    if (elInput) {
      elInput.readOnly = false
      elInput.blur()
      elInput.focus()
    }
  })
}
document.addEventListener('focusin', fixElSelect)
document.addEventListener('click', fixElSelect)
document.addEventListener('touchstart', fixElSelect)
