import Vue from 'vue'
import VueRouter from 'vue-router'

import layouts from './layout'

import beforeEachRouter from './hooks/beforeEachRouter'
import beforeEnterAuth from './hooks/beforeEnterAuth'
// import beforeEnterBooking from './hooks/beforeEnterBooking'
import beforeEnterBooks from './hooks/beforeEnterBooks'
import beforeEnterSuccess from './hooks/beforeEnterSuccess'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)
const routes = [
  // {
  //   path: '*',
  //   name: 'NotFound',
  //   component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
  //   meta: {
  //     layout: layouts.contenOnly
  //   }
  // },
  {
    path: '/',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/auth/Register.vue'),
    beforeEnter: beforeEnterAuth,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue'),
    beforeEnter: beforeEnterAuth,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/auth/Forgot.vue'),
    beforeEnter: beforeEnterAuth,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import(/* webpackChunkName: "forget" */ '../views/auth/AfterForgot.vue'),
    beforeEnter: beforeEnterAuth,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/reset/:token',
    name: 'reset',
    component: () => import(/* webpackChunkName: "forget" */ '../views/auth/Reset.vue'),
    // beforeEnter: beforeEnterAuth,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    beforeEnter: beforeEnterAuth,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      auth: true,
      layout: layouts.navLeft
    }
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import(/* webpackChunkName: "edit" */ '../views/user/Edit.vue'),
    meta: {
      auth: true,
      layout: layouts.navLeft
    }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import(/* webpackChunkName: "password" */ '../views/user/Password.vue'),
    meta: {
      auth: true,
      layout: layouts.navLeft
    }
  },
  {
    path: '/qr/:id',
    name: 'qr',
    component: () => import(/* webpackChunkName: "qr" */ '../views/QR.vue'),
    beforeEnter: beforeEnterAuth,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/booking/:string?',
    name: 'booking',
    component: () => import(/* webpackChunkName: "booking" */ '../views/booking/Booking.vue'),
    beforeEnter: beforeEnterBooks,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/mybooks',
    name: 'mybooks',
    component: () => import(/* webpackChunkName: "mybooks" */ '../views/booking/Mybooks.vue'),
    beforeEnter: beforeEnterBooks,
    meta: {
      layout: layouts.navLeft
    }
  },
  {
    path: '/book/:id',
    name: 'book',
    component: () => import(/* webpackChunkName: "book" */ '../views/booking/Book.vue'),
    beforeEnter: beforeEnterBooks,
    meta: {
      layout: layouts.navLeft
    }
  },
  {
    path: '/newbook/:id',
    name: 'newbook',
    component: () => import(/* webpackChunkName: "book" */ '../views/booking/NewBook.vue'),
    beforeEnter: beforeEnterBooks,
    meta: {
      layout: layouts.navLeft
    }
  },
  {
    path: '/change/:id',
    name: 'change',
    component: () => import(/* webpackChunkName: "change" */ '../views/booking/Change.vue'),
    beforeEnter: beforeEnterBooks,
    meta: {
      layout: layouts.navLeft
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "success" */ '../views/booking/Success.vue'),
    beforeEnter: beforeEnterSuccess,
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '../views/booking/Error.vue'),
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/return',
    name: 'return',
    component: () => import(/* webpackChunkName: "return" */ '../views/booking/Return.vue'),
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
    meta: {
      layout: layouts.contenOnly
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => beforeEachRouter(to, from, next))

export default router
