import QRResource from '../../api/qr.service'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getQR: ({ commit }, id) => {
      return QRResource.get(id)
        .then(response => {
          return response.data
        })
        .catch(err => {
          throw err
        })
    }
  }
}
