/*
 * Axios is the most popular HTTP Client library,
 * by the time of writing this piece of code.
 */
import axios from 'axios'
import store from '../store/'
import router from '../router/index'

let HTTP = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL
})

HTTP.interceptors.request.use(function (config) {
  if (localStorage.getItem('token') !== null) {
    config.headers.common['access-token'] = localStorage.getItem('token')
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

HTTP.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('logout')
    router.push('/login')
  }
  return Promise.reject(error.response.data)
})

export default HTTP
