<template>
  <div class="layout-container flex justify-center">
    <vertical-nav
      :position="navPos"
      :collapse-nav="collapseNav"
      :open-sidebar.sync="openSidebar"
      @collapse-nav-toggle="collapseNav = !collapseNav"
      @push-page="closeSidebar"
      v-if="navPos === 'left'"/>
    <div class="container flex column box grow">
      <div class="header" v-if="toolbar === 'top'">
        <Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos"/>
      </div>
      <div class="main box grow flex">
        <transition name="fade-top" mode="out-in">
          <keep-alive>
            <router-view class="view box grow"/>
          </keep-alive>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import VerticalNav from './core/vertical-nav.vue'
import Toolbar from './core/toolbar.vue'

export default {
  name: 'app',

  data () {
    return {
      collapseNav: false,
      openSidebar: false,
      innerWidth: 0,
      loading: this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
    }
  },

  components: {
    VerticalNav,
    Toolbar
  },

  computed: {
    navPos () {
      if (this.innerWidth <= 768 && (this.$store.getters.navPos === 'top' || this.$store.getters.navPos === 'bottom')) {
        return 'left'
      }
      return this.$store.getters.navPos
    },
    toolbar () {
      return this.$store.getters.toolbar
    },
    footer () {
      return this.$store.getters.footer
    },
    boxed () {
      return this.$store.getters.boxed
    },
    roundedCorners () {
      return this.$store.getters.roundedCorners
    },
    viewAnimation () {
      return this.$store.getters.viewAnimation || 'none'
    },
    isLogged () {
      return this.$store.getters.isLogged
    },
    splashScreen () {
      return this.$store.getters.splashScreen
    }
  },

  methods: {
    resizeOpenNav () {
      this.innerWidth = window.innerWidth
      if (window.innerWidth <= 768) {
        this.collapseNav = false
      }
    },
    closeSidebar () {
      this.openSidebar = false
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setSplashScreen') {
        if (state.splashScreen) {
          this.loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        } else {
          this.loading.close()
        }
      }
    })
  },
  mounted () {
    this.resizeOpenNav()
    window.addEventListener('resize', this.resizeOpenNav)
  },
  beforeDestroy () {
    this.unsubscribe()
    window.removeEventListener('resize', this.resizeOpenNav)
  }
}
</script>

<style lang="scss">
@import './assets/scss/_variables';
@import './assets/scss/_mixins';

.Loading {
  font-size: 24px !important;
  color: white !important;
}

.layout-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: $background-color;

  .container {
  overflow: hidden;

  .main {
    position: relative;
    overflow: hidden;
    padding: 0 30px;
  }

  .view {
    padding: 20px;
    box-sizing: border-box;
    transition: all .4s cubic-bezier(.55,0,.1,1);
    backface-visibility: hidden;

  }
  .fade-top-in-out-enter { opacity: 0; transform: translate(0, 30px); }
  .fade-top-in-out-leave-active { opacity: 0; transform: translate(0, 30px); }

  .fade-top-enter { opacity: 0; transform: translate(0, 30px); }
  .fade-top-leave-active { opacity: 0; transform: translate(0, -30px); }

  .fade-bottom-in-out-enter { opacity: 0; transform: translate(0, -30px); }
  .fade-bottom-in-out-leave-active { opacity: 0; transform: translate(0, -30px); }

  .fade-bottom-enter { opacity: 0; transform: translate(0, -30px); }
  .fade-bottom-leave-active { opacity: 0; transform: translate(0, 30px); }

  .fade-left-enter { opacity: 0; transform: translate(30px, 0); }
  .fade-left-leave-active { opacity: 0; transform: translate(-30px, 0); }

  .fade-right-enter { opacity: 0; transform: translate(-30px, 0); }
  .fade-right-leave-active { opacity: 0; transform: translate(30px, 0); }

  .fade-enter { opacity: 0; }
  .fade-leave-active { opacity: 0; }

  }

  &.boxed {
  max-width: 1300px;
  margin: 0 auto;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }

  &.footer-above {
  padding-bottom: 40px;
  position: relative;
  }

}

html:not(.ie) {
  .layout-container {
  .container {
    max-width: 1920px;
  }
  }
}

@media (min-width: 1920px) {
  .layout-container:not(.boxed) {
  &.column {
    .container {
    margin: 0 auto;
    }
  }
  }
}

@media (max-width: 768px) {
  .layout-container {
  .container {

    .header {
    height: 50px;
    background: $background-color;
    color: $text-color;
    box-shadow: 0px -20px 10px 20px rgba(0, 0, 0, 0.25);
    margin: 0;
    margin-bottom: 5px;

    .toggle-sidebar {
      box-shadow: none !important;
    }

    }

    .main {
    padding: 0 5px;
    }

    .view {
    max-width: 100%;
    padding: 15px;
    padding-left: 15px;
    padding-right: 15px;
    }
  }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
