<template>
  <div class="toolbar flex align-center justify-space-between">
    <div class="box-left box grow flex">
      <button @click="toggleSidebar" v-if="menuBurger !== 'right'" class="toggle-sidebar card-base card-shadow--small">
        <i class="mdi mdi-menu"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toolbar',
  props: ['menuBurger'],
  data () {
    return {}
  },
  methods: {
    toggleSidebar () {
      this.$emit('toggle-sidebar')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/_variables';
@import '../assets/scss/_mixins';

.toolbar {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  .toggle-sidebar {
    border: 1px solid transparent;
    height: 32px;
    min-height: 32px;
    line-height: 32px;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    box-sizing: border-box;
    text-align: center;
    margin: 0;
    outline: none;
    margin-right: 5px;
    font-size: 24px;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    color: $text-color;
    background: white;
    display: none;
    opacity: 0;
    transition: all .5s;

    &__right {
      margin-right: 0px;
      margin-left: 5px;
    }

    &:hover, &:focus, &:active {
      color: $text-color-accent;
      border-color: $text-color-accent;
    }
  }

}

@media (max-width: 650px) {
  .toolbar {
    .username {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .toolbar {
    padding: 0 10px;

    .toggle-sidebar {
      display: block;
      opacity: 1;
    }
  }
}
</style>
