import HTTP from './http-common'

let PromocodeResource = {
  get (code) {
    return HTTP.get('promocode/' + code)
  },
  getByUser (id) {
    return HTTP.get('promocode/user/' + id)
  }
}

export default PromocodeResource
