// import UserResource from '../../api/user.service'
import AuthService from '../../api/auth.service'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createUser: ({ dispatch, getters }, body) => {
      if (getters.isAuthenticated) {
        return getters.user
      } else {
        return AuthService.signup(body)
          .then(response => {
            return response
          })
          .catch(err => {
            throw err
          })
      }
    },
    changePassword: ({ commit }, payload) => {
      return AuthService.changePassword(payload)
        .catch(err => {
          throw err
        })
    }
  }
}
