import store from '../../store'

export default (to, from, next) => {
  const books = store.state.booking.books
  const calendar = store.state.booking.calendar
  const venues = store.state.booking.venues
  // const promocodes = store.state.booking.promocodes
  const addons = store.state.booking.addons
  const capacity = store.state.booking.capacity
  const settings = !store.state.booking.settings.isDownload
  // const testIds = store.state.testIds

  const promise = new Promise((resolve) => resolve(true))

  // if (store.getters.isAuthenticated) {
  // if (store.state.auth.user && testIds.includes(store.state.auth.user._id)) {
  promise
    .then(() => {
      const data = {
        // books: books.length === 0,
        calendar: calendar.length === 0,
        venues: venues.length === 0,
        // promocodes: promocodes.length === 0,
        addons: addons.length === 0,
        capacity: capacity.length === 0,
        settings
      }
      if (Object.values(data).some(x => x)) {
        return store.dispatch('getAll', data)
      }
    })
    .then(() => {
      if (books.length === 0 && store.getters.isAuthenticated) {
        return store.dispatch('getBooks')
      }
    })
    .finally(() => {
      next()
    })
    // } else {
    //   next('/home')
    // }
  // } else {
  //   next('/')
  // }
}
