import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import * as modules from './modules'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    layout: {
      navPos: 'left', // top, bottom, left, right, false
      toolbar: 'top', // top, bottom, false
      footer: true, // above, below, false
      boxed: false, // true, false
      roundedCorners: false, // true, false
      viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
    },
    splashScreen: true,
    logged: true,
    testIds: [
      '60c2f6677e51f221cf395916',
      '60be63f2a9946f532a578cf4',
      '60c199866ed4d2a5035c6a39',
      '60cd47bf324fff063944d48f',
      '60e6b81a791cdbbd9c8d6795'
    ],
    testEmails: [
      'ahmedmagdi.row@gmail.com',
      'reem@rolldxb.com',
      'vadim@rolldxb.com',
      'aliyah@rolldxb.com',
      'worhols@gmail.com',
      'awakadev@gmail.com'
    ]
  },
  mutations: {
    setLayout (state, payload) {
      if (payload && payload.navPos !== undefined) {
        state.layout.navPos = payload.navPos
      }

      if (payload && payload.toolbar !== undefined) {
        state.layout.toolbar = payload.toolbar
      }

      if (payload && payload.footer !== undefined) {
        state.layout.footer = payload.footer
      }

      if (payload && payload.boxed !== undefined) {
        state.layout.boxed = payload.boxed
      }

      if (payload && payload.roundedCorners !== undefined) {
        state.layout.roundedCorners = payload.roundedCorners
      }

      if (payload && payload.viewAnimation !== undefined) {
        state.layout.viewAnimation = payload.viewAnimation
      }
    },
    setLogin (state, payload) {
      state.logged = true
    },
    setLogout (state, payload) {
      state.layout.navPos = null
      state.layout.toolbar = null
      state.logged = false
    },
    setSplashScreen (state, payload) {
      state.splashScreen = payload
    }
  },
  actions: {
    setSplashScreen ({ commit }, payload) {
      commit('setSplashScreen', payload)
    }
  },
  getters: {
    layout (state, getters) {
      return state.layout
    },
    navPos (state, getters) {
      return state.layout.navPos
    },
    toolbar (state, getters) {
      return state.layout.toolbar
    },
    footer (state, getters) {
      return state.layout.footer
    },
    boxed (state, getters) {
      return state.layout.boxed
    },
    roundedCorners (state, getters) {
      return state.layout.roundedCorners
    },
    viewAnimation (state, getters) {
      return state.layout.viewAnimation
    },
    isLogged (state, getters) {
      return state.logged
    },
    splashScreen (state, getters) {
      return state.splashScreen
    }
  },
  plugins: [createPersistedState({ paths: ['layout'] })],
  strict: true,
  modules
})
