import store from '../../store'
import layouts from '../layout'

const l = {
  contenOnly () {
    store.commit('setLayout', layouts.contenOnly)
  },
  navLeft () {
    store.commit('setLayout', layouts.navLeft)
  },
  navRight () {
    store.commit('setLayout', layouts.navRight)
  },
  navTop () {
    store.commit('setLayout', layouts.navTop)
  },
  navBottom () {
    store.commit('setLayout', layouts.navBottom)
  },
  set (layout) {
    store.commit('setLayout', layout)
  }
}

export default (to, from, next) => {
  store.commit('setSplashScreen', true)

  if (to && to.meta && to.meta.layout) {
    l.set(to.meta.layout)
  }

  if (!to.meta.auth) {
    return next()
  }

  if (!store.getters.isAuthenticated) {
    return next('/register')
  }
  next()
}
