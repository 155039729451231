import Vue from 'vue'
import router from '../router/'

const onCommandParams = {
  install (Vue) {
    Vue.prototype.$onCommandParams = (name, params) => {
      return router.push({ name, params })
    }
  }
}

Vue.use(onCommandParams)
