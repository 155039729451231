import { render, staticRenderFns } from "./vertical-nav.vue?vue&type=template&id=61c00e6f&"
import script from "./vertical-nav.vue?vue&type=script&lang=js&"
export * from "./vertical-nav.vue?vue&type=script&lang=js&"
import style0 from "./vertical-nav.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports