import HTTP from './http-common'

let UserResource = {
  create (body) {
    return HTTP.post('users/', body)
  },

  me () {
    return HTTP.get('users/me')
  },

  update (id, body) {
    return HTTP.put('users/' + id, body)
  },

  changePassword (body) {
    return HTTP.put('users/me/password', body)
  },

  resetPassword (body) {
    return HTTP.put('users/me/reset', body)
  },

  editProfile (body) {
    return HTTP.put('users/me/profile', body)
  }
}

export default UserResource
