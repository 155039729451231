import CalendarResource from '../../api/calendar.service'
import PromocodeResource from '../../api/promocode.service'
import BookingResource from '../../api/booking.service'
import AddonResource from '../../api/addon.service'
import CapacityResource from '../../api/capacity.service'
import SettingsResource from '../../api/settings.service'
import AllResource from '../../api/all.service'

export default {
  state: {
    settings: {
      blockBooking: 45,
      editBooking: 24,
      refundBooking: 24,
      validTimeBooking: 15,
      isDownload: false
    },
    books: [],
    calendar: [],
    venues: [],
    promocodes: [],
    personalPromos: [],
    addons: [],
    capacity: [],
    activeStep: 0,
    activeChangeStep: 0,
    sessions: [
      {
        name: 'Disco experience',
        isSpecial: false,
        desc: '',
        time: '10:00 - 11:30',
        capacity: 90,
        left: 82,
        price: '95 - 112'
      },
      {
        name: 'Disco experience',
        isSpecial: false,
        desc: '',
        time: '11:30 - 13:00',
        capacity: 90,
        left: 89,
        price: '95 - 112'
      },
      {
        name: 'Disco experience',
        isSpecial: false,
        desc: '',
        time: '13:00 - 14:30',
        capacity: 90,
        left: 80,
        price: '95 - 112'
      },
      {
        name: 'Disco experience',
        isSpecial: false,
        desc: '',
        time: '14:30 - 16:00',
        capacity: 90,
        left: 54,
        price: '95 - 112'
      },
      {
        name: 'Disco experience',
        isSpecial: false,
        desc: '',
        time: '16:00 - 17:30',
        capacity: 90,
        left: 40,
        price: '95 - 112'
      },
      {
        name: 'Disco experience',
        isSpecial: false,
        desc: '',
        time: '17:45 - 19:15',
        capacity: 90,
        left: 0,
        price: '95 - 112'
      },
      {
        name: 'Disco experience',
        isSpecial: false,
        desc: '',
        time: '20:00 - 21:30',
        capacity: 90,
        left: 18,
        price: '95 - 112'
      },
      {
        name: 'Ladies only night',
        isSpecial: true,
        desc: 'for ladies only',
        time: '21:45 - 23:15',
        capacity: 90,
        left: 12,
        price: '115 - 135'
      },
      {
        name: 'Ladies only night',
        isSpecial: true,
        desc: 'for ladies only',
        time: '23:30 - 01:00',
        capacity: 90,
        left: 8,
        price: '115 - 135'
      }
    ],
    tickets: [
      {
        name: 'With Skates',
        isSpecial: false,
        desc: '',
        price: 105
      },
      {
        name: 'No Skates. Rental with pads',
        isSpecial: true,
        desc: 'Please wear long, fresh and clean socks, otherwise we will sell you a pair!',
        price: 112
      },
      {
        name: 'MOXI/Reidell Skates',
        isSpecial: true,
        desc: 'If you own one of our exclusive, fancy moxies, bring them with you and enjoy better prices !',
        price: 95
      }
    ],
    basket: {
      day: '',
      session: {
        idx: -1,
        name: '',
        time: ''
      },
      venue: {
        id: '',
        name: '',
        description: ''
      },
      ticket: {
        idx: -1,
        name: '',
        persons: 1,
        price: 0
      },
      customer: {
        id: '',
        first: '',
        surname: '',
        email: '',
        phone: '',
        birthday: ''
      },
      discount: {
        promocode: '',
        percent: 0,
        isGroup: false,
        isPersonal: false,
        promoId: ''
      },
      addons: []
    },
    clearBasket: {
      session: {
        idx: -1,
        name: '',
        time: ''
      },
      ticket: {
        idx: -1,
        name: '',
        persons: 1,
        price: 0
      }
    },
    steps: [
      { step: 'Date' },
      { step: 'Venue' },
      { step: 'Session' },
      { step: 'Tickets' },
      { step: 'Addons' },
      { step: 'Details' },
      { step: 'Payment' }
    ],
    defaultBook: {
      _id: '',
      discount: {
        isGroup: false,
        isPersonal: false,
        promocode: '',
        percent: 0,
        promoId: ''
      },
      addons: [],
      isRefund: false,
      day: '',
      session: {
        idx: 0,
        name: '',
        time: ''
      },
      ticket: {
        idx: 0,
        name: '',
        persons: 1,
        price: 0
      },
      customer: {
        id: '',
        first: '',
        surname: '',
        email: '',
        phone: '',
        birthday: ''
      },
      amount: 0,
      paymentStatus: 'paid',
      useStatus: 'booked',
      createDate: ''
    }
  },
  getters: {
    basket: state => state.basket,
    step0: state => {
      let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      return state.basket.day !== ''
        ? new Date(state.basket.day).toLocaleString('en-GB', options)
        : ''
    },
    step4: state => {
      let arr = state.basket.addons.map(a => a.count + ' ' + a.name)
      return state.basket.addons.length > 0
        ? arr.concat('AED ' + state.basket.addons.reduce((a, c) => {
          return a + (c.count * c.cost)
        }, 0))
        : []
    },
    descriptions: (state, getters) => {
      let step0 = getters.step0.split(',').map(e => e.trim())

      let step1 = [
        state.basket.venue.name
      ]

      let step2 = [
        state.basket.session.name,
        state.basket.session.time
      ]

      let step3 = state.basket.ticket.price > 0
        ? [
          state.basket.ticket.persons > 1
            ? state.basket.ticket.persons + ' tickets'
            : state.basket.ticket.persons + ' ticket',
          state.basket.ticket.name,
          'AED ' + (Math.floor(state.basket.ticket.price * 100) / 100 * state.basket.ticket.persons)
        ]
        : []

      let step4 = getters.step4

      let step5 = [
        state.basket.customer.first + ' ' + state.basket.customer.surname,
        state.basket.discount.percent > 0
          ? 'Discount ' + state.basket.discount.percent + '%'
          : ''
      ]
      return [step0, step1, step2, step3, step4, step5]
    },
    calendar: state => {
      let today = new Date().getTime()

      return state.calendar.filter(d => {
        let day = new Date(d.day).getTime()

        return day > today - (1000 * 60 * 60 * 24) &&
          day < today + (1000 * 60 * 60 * 24 * 30)
      })
    },
    calendarDaysArray: (state, getters) => {
      return getters.calendar.map(d => d.day)
    },
    calendarEventsArray: (state, getters) => {
      return getters.calendar.map(d => {
        if (getters.basket.day === d.day) {
          return {
            start: d.day,
            display: 'background',
            classNames: ['sess selected'],
            title: d.day.slice(-2)
          }
        } else {
          let sess = getters.getSessionsByDay[d.day + '_' + d.venueId]
            .reduce((a, c) => {
              return {
                left: a.left + c.left,
                capacity: a.capacity + c.capacity
              }
            }, { left: 0, capacity: 0 })

          let per = Math.ceil(sess.left * 100 / sess.capacity)
          let classNames = []
          switch (true) {
            case per >= 75:
              classNames = ['sess', 'sess-0']
              break
            case per >= 50 && per < 75:
              classNames = ['sess', 'sess-1']
              break
            case per >= 25 && per < 50:
              classNames = ['sess', 'sess-2']
              break
            case per >= 0 && per < 25:
              classNames = ['sess', 'sess-3']
              break
            case per === 0:
              classNames = ['sess', 'sess-4']
              break
            default:
              classNames = ['sess', 'sess-5']
          }
          return {
            start: d.day,
            display: 'background',
            classNames,
            title: d.day.slice(-2) }
        }
      })
    },
    getVenuesById: state => state.venues.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {}),
    getVenuesByDay: (state, getters) => state.calendar.reduce((out, d) => {
      if (d.hasOwnProperty('venueId')) {
        let venue = getters.getVenuesById[d.venueId]
        out[d.day] = out[d.day] || []
        out[d.day] = out[d.day].concat({
          venueId: d.venueId,
          name: venue.name,
          description: venue.description
        })
      }
      return out
    }, {}),
    getVenues: (state, getters) => {
      return getters.getVenuesByDay[state.basket.day] || []
    },
    getSessionsByDay: state => state.calendar.reduce((out, d) => {
      let c = state.capacity.filter(c => c.day === d.day)
      out[d.day + '_' + d.venueId] = d.hasOwnProperty('sessions')
        ? d.sessions.map((s, index) => {
          let occupied = c.find(o => o.session === index)
          let left = occupied
            ? s.capacity - occupied.persons
            : s.capacity

          return Object.assign({ left }, s)
        })
        : []
      return out
    }, {}),
    getSessions: (state, getters) => {
      return getters.getSessionsByDay[state.basket.day + '_' + state.basket.venue.id] || []
    },
    isSpecialSessions: (state, getters) => {
      return [...new Map(getters.getSessions
        .filter(s => s.isSpecial)
        .map(s => [s['sessionId'], { name: s.name, description: s.description }]))
        .values()]
    },
    getTypes: (state, getters) => {
      if (state.basket.session.idx !== -1) {
        let t = getters.getSessions[state.basket.session.idx]

        return t && t.hasOwnProperty('types')
          ? t.types
          : []
      } else {
        return []
      }
    },
    isSpecialTypes: (state, getters) => {
      return getters.getTypes.filter(t => t.isSpecial)
    },
    isValidPromo: (state, getters, rootState, rootGetters) => code => {
      return state.promocodes.filter(p => {
        let day = new Date(state.basket.day).getTime()
        let start = new Date(p.startDate).getTime()
        let end = new Date(p.endDate).getTime()

        return p.isActive && p.code.toLowerCase() === code.toLowerCase() &&
          (p.quantity === -1 || p.used < p.quantity) &&
          start <= day && day <= end &&
          (!p.isPersonal ||
            (rootGetters.isAuthenticated &&
              rootState.auth.user._id === p.customerId))
      })
    },
    getBooksById: state => state.books.reduce((out, item) => {
      out[item._id] = item
      return out
    }, {}),
    personalPromosForSelect: (state, getters) => {
      return state.personalPromos.map((el) => {
        return {
          value: el.code,
          label: el.code + ' (Discount ' + el.discount + '%' + (el.isGroup ? ', group)' : ')')
        }
      })
    }
  },
  mutations: {
    SET_BOOKS: (state, items) => {
      state.books = items
    },
    ADD_BOOK: (state, item) => {
      state.books.push(item)
    },
    EDIT_BOOK: (state, { id, content }) => {
      let idx = state.books.findIndex(l => l._id === id)
      if (idx !== -1) {
        let newItem = Object.assign({}, state.books[idx], content)
        state.books.splice(idx, 1, newItem)
      }
    },
    SELECT_DAY: (state, { day, venuesInSelectedDay, venue }) => {
      state.basket.day = day
      if (venuesInSelectedDay === 1) {
        state.basket.venue = {
          id: venue.venueId,
          name: venue.name,
          description: venue.description
        }
        state.activeStep += 1
      }
      state.activeStep += 1
    },
    SELECT_VENUE: (state, { id, name, description }) => {
      state.basket.venue = {
        id,
        name,
        description
      }
      state.activeStep = 2
    },
    SELECT_SESSION: (state, { idx }) => {
      let day = state.calendar.find(c => c.day === state.basket.day && c.venueId === state.basket.venue.id)
      let session = day && day.hasOwnProperty('sessions') &&
        day.sessions.length > idx
        ? day.sessions[idx]
        : state.sessions[idx]

      state.basket.session = {
        idx: idx,
        name: session.name,
        time: session.time
      }
      state.activeStep += 1
    },
    SELECT_TICKET: (state, { idx, sessionIdx }) => {
      let day = state.calendar.find(c => c.day === state.basket.day && c.venueId === state.basket.venue.id)
      let session = day && day.hasOwnProperty('sessions') && day.sessions.length > sessionIdx
        ? day.sessions[sessionIdx]
        : undefined
      let ticket = session && session.hasOwnProperty('types') && session.types.length > idx
        ? session.types[idx]
        : state.tickets[idx]

      state.basket.ticket.idx = idx
      state.basket.ticket.name = ticket.name
      state.basket.ticket.price = ticket.price
      state.activeStep += 1
    },
    SELECT_PERSONS: (state, { persons }) => {
      state.basket.ticket.persons = persons
    },
    SELECT_ADDONS: (state, addons) => {
      state.basket.addons = addons
        .filter(a => a.count > 0).map((x) => Object.assign({ }, x))
    },
    SELECT_EXTRAS: (state, user) => {
      state.basket.customer = {
        id: user.id,
        first: user.first,
        surname: user.surname,
        email: user.email,
        phone: user.phone,
        birthday: user.birthday
      }
      state.activeStep += 1
    },
    SELECT_DISCOUNT: (state, { promocode, percent, isGroup, isPersonal, promoId, posibleCount }) => {
      state.basket.discount = {
        promocode: promocode,
        percent: percent,
        isGroup: isGroup,
        isPersonal: isPersonal,
        promoId: promoId,
        posibleCount: posibleCount
      }
    },
    SELECT_STEP: (state, { idx }) => {
      state.activeStep = idx
    },
    CLEAR_SESSION: (state) => {
      state.basket.session = {
        idx: -1,
        name: '',
        time: ''
      }
    },
    CLEAR_TICKET: (state) => {
      state.basket.ticket.idx = -1
      state.basket.ticket.name = ''
      state.basket.ticket.price = 0
      state.basket.ticket.persons = 1
    },
    CLEAR_ADDONS: (state) => {
      state.basket.addons = []
    },
    CLEAR_BASKET: (state) => {
      state.basket = {
        day: '',
        venue: {
          id: '',
          name: '',
          description: ''
        },
        session: {
          idx: -1,
          name: '',
          time: ''
        },
        ticket: {
          idx: -1,
          name: '',
          persons: 1,
          price: 0
        },
        customer: {
          id: '',
          first: '',
          surname: '',
          email: '',
          phone: '',
          birthday: ''
        },
        discount: {
          promocode: '',
          percent: 0,
          isGroup: false,
          isPersonal: false,
          promoId: ''
        },
        addons: []
      }
    },
    SET_CALENDAR: (state, payload) => {
      state.calendar = payload
    },
    SET_VENUES: (state, payload) => {
      state.venues = payload
    },
    SET_PROMOCODES: (state, payload) => {
      state.promocodes = payload
    },
    SET_ADDONS: (state, payload) => {
      state.addons = payload
    },
    SET_SETTINGS: (state, payload) => {
      state.settings = Object.assign({}, state.settings, payload, { isDownload: true })
    },
    SET_CAPACITY: (state, payload) => {
      state.capacity = payload
    },
    REMOTE_BOOK: (state, { id }) => {
      state.books = state.books.filter(item => item._id !== id)
    },
    SET_PERSONAL_PROMO: (state, payload) => {
      state.personalPromos = payload
    }
  },
  actions: {
    createBook: ({ commit, getters }, amount) => {
      let data = Object.assign(getters.basket, {
        amount: amount,
        paymentStatus: 'paid',
        useStatus: 'booked'
      })
      return BookingResource.create(data)
        .then(response => {
          commit('ADD_BOOK', response.data)
          commit('CLEAR_BASKET')
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    finalBook: ({ commit }, payload) => {
      return BookingResource.final(payload)
        .then(response => {
          commit('EDIT_BOOK', { id: response.data._id, content: response.data })
          return response.data._id
        })
        .catch(err => {
          throw err
        })
    },
    refundBook: ({ commit }, payload) => {
      return BookingResource.refund(payload)
        .then(response => {
          commit('EDIT_BOOK', { id: response.data._id, content: response.data })
        })
        .catch(err => {
          throw err
        })
    },
    remoteBook: ({ commit }, { id }) => {
      return BookingResource.remote(id)
        .then(response => {
          commit('REMOTE_BOOK', { id })
          return true
        })
        .catch(err => {
          throw err
        })
    },
    repeatBook: ({ commit }, { id }) => {
      return BookingResource.repeat(id)
        .then(response => {
          commit('ADD_BOOK', response.data)
          commit('REMOTE_BOOK', { id })
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    editBook: ({ commit }, { id, content }) => {
      return BookingResource.update(id, content)
        .then(() => {
          commit('EDIT_BOOK', { id, content })
        })
        .catch(err => {
          throw err
        })
    },
    getBooks: ({ commit }) => {
      return BookingResource.get()
        .then(response => {
          commit('SET_BOOKS', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    getSettings: ({ commit }) => {
      return SettingsResource.get()
        .then(response => {
          commit('SET_SETTINGS', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    getAddons: ({ commit }) => {
      return AddonResource.get()
        .then(response => {
          commit('SET_ADDONS', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    getPromocodes: ({ commit }, payload) => {
      return PromocodeResource.get(payload)
        .then(response => {
          commit('SET_PROMOCODES', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    getCalendar: ({ commit }) => {
      return CalendarResource.get()
        .then(response => {
          commit('SET_CALENDAR', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    getCapacity: ({ commit }) => {
      return CapacityResource.get()
        .then(response => {
          commit('SET_CAPACITY', response.data)
          return response.data
        })
        .catch(err => {
          throw err
        })
    },
    selectDay: ({ commit, getters }, { day, isSame }) => {
      if (!isSame) {
        commit('CLEAR_SESSION')
        commit('CLEAR_TICKET')
        commit('CLEAR_ADDONS')
      }
      let venues = getters.getVenuesByDay[day]
      commit('SELECT_DAY', {
        day,
        venuesInSelectedDay: venues.length,
        venue: venues[0] })
    },
    selectVenue: ({ commit, getters }, { id, isSame }) => {
      if (!isSame) {
        commit('CLEAR_SESSION')
        commit('CLEAR_TICKET')
        commit('CLEAR_ADDONS')
      }
      let venue = getters.getVenuesById[id]
      commit('SELECT_VENUE', { id, name: venue.name, description: venue.description })
    },
    selectSession: ({ commit }, { idx, isSame }) => {
      if (!isSame) {
        commit('CLEAR_TICKET')
        commit('CLEAR_ADDONS')
      }
      commit('SELECT_SESSION', { idx })
    },
    selectTicket: ({ commit }, { idx, isSame, sessionIdx }) => {
      if (!isSame) {
        commit('CLEAR_ADDONS')
      }
      commit('SELECT_TICKET', { idx, sessionIdx })
    },
    selectPersons: ({ commit }, { persons }) => {
      commit('CLEAR_ADDONS')
      commit('SELECT_PERSONS', { persons })
    },
    selectAddons: ({ commit }, addons) => {
      commit('SELECT_ADDONS', addons)
    },
    selectExtras: ({ dispatch, commit, getters }, user) => {
      return dispatch('createUser', user)
        .then(response => {
          let dataUser = Object.assign({ id: response._id }, response)
          commit('SELECT_EXTRAS', dataUser)
          if (user.promocode !== '') {
            return dispatch('getPromocodes', user.promocode)
          }
        })
        .then(() => {
          let promo = getters.isValidPromo(user.promocode)
          if (promo.length > 0) {
            commit('SELECT_DISCOUNT', {
              promocode: promo[0].code,
              percent: promo[0].discount,
              isGroup: promo[0].isGroup,
              isPersonal: promo[0].isPersonal,
              promoId: promo[0]._id,
              posibleCount: promo[0].quantity === -1
                ? getters.basket.ticket.persons
                : promo[0].quantity - promo[0].used
            })
          } else {
            commit('SELECT_DISCOUNT', {
              promocode: '',
              percent: 0,
              isGroup: false,
              isPersonal: false,
              promoId: '',
              posibleCount: 0
            })
          }
        })
    },
    selectStep: ({ commit }, { idx }) => {
      commit('SELECT_STEP', { idx })
    },
    getAll: ({ commit }, body) => {
      return AllResource.show(body)
        .then(response => {
          if (response.data.hasOwnProperty('calendar')) {
            commit('SET_CALENDAR', response.data.calendar)
          }
          if (response.data.hasOwnProperty('venues')) {
            commit('SET_VENUES', response.data.venues)
          }
          if (response.data.hasOwnProperty('books')) {
            commit('SET_BOOKS', response.data.books)
          }
          if (response.data.hasOwnProperty('promocodes')) {
            commit('SET_PROMOCODES', response.data.promocodes)
          }
          if (response.data.hasOwnProperty('addons')) {
            commit('SET_ADDONS', response.data.addons)
          }
          if (response.data.hasOwnProperty('capacity')) {
            commit('SET_CAPACITY', response.data.capacity)
          }
          if (response.data.hasOwnProperty('settings')) {
            commit('SET_SETTINGS', response.data.settings)
          }
        })
        .catch(err => {
          throw err
        })
    },
    getPersonalPromo: ({ commit }, id) => {
      return PromocodeResource.getByUser(id)
        .then(response => {
          commit('SET_PERSONAL_PROMO', response.data)
        })
        .catch(err => {
          throw err
        })
    }
  }
}
